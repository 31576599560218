.rounded-rect {
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 50px -25px black;
}

.flex-center {
  position: absolute;
  /* display: flex; */
  justify-content: center;
  /* align-items: center; */
}

.flex-center.right {
  right: 0px;
}

.sidebar-content {
  position: absolute;
  width: 95%;
  height: 95%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: gray;
  padding: 10px 10px;
}

.sidebar-toggle {
  position: absolute;
  width: 1.3em;
  height: 1.3em;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-toggle.left {
  right: -1.5em;
}

.sidebar-toggle.right {
  left: -1.5em;
}

.sidebar-toggle:hover {
  color: #0aa1cf;
  cursor: pointer;
}

.sidebar {
  transition: transform 1s;
  z-index: 1;
  width: 300px;
  height: 100%;
  margin-top: 15px;
  margin-right: 10px;
}

.right.collapsed {
  transform: translateX(295px);
}

.mapboxgl-popup.mapboxgl-popup-anchor-bottom{
  opacity: 0.8;
}
